import React, { useState, useEffect } from 'react'

function FixedMenuBar({orgs}) {
  const [isFixed, setIsFixed] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollThreshold = 640;
      setIsFixed(window.scrollY >= scrollThreshold);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div style={{
      position: isFixed ? 'fixed' : 'absolute',
      top: isFixed ? '0' : undefined,
      marginTop: isFixed ? '0' : '-80px',
      // left: isFixed ? '33%' : '50%',
      transform: 'translateX(-50%)',
      zIndex: 50,
      backgroundColor: 'black',
      maxWidth: window.innerWidth < 768 ? '100%' : '80vw',
      padding: isFixed ? '18px 100%' : '50px 90px',
      display: 'flex',
      justifyContent: 'center',
      left: '50%',
      alignItems: 'center',
      borderRadius: '5px',
    }} className="">
      {orgs && orgs.length > 0 && orgs.map((org, index) => (
        <a href={`#${org.slug}`} key={index} className="flex items-center">
          <span style={{fontFamily: "goldbold", whiteSpace: "nowrap"}} className='text-white text-[3vw] sm:text-[1.8vw] font-bold whitespace-normal break-words'>{org.name}</span>
          <div className="h-[50px] w-px bg-white mx-[4vw]" />
        </a>
      ))}

      <a href="#rent-space" className="flex items-center whitespace-nowrap">
        <span style={{fontFamily: "goldbold"}} className='text-white text-[3vw] sm:text-[1.8vw] font-bold'>Rent Space</span>
        <div className="h-[50px] w-px bg-white mx-[4vw]" />
      </a>
      <a href="#faq" className="whitespace-nowrap">
        <span style={{fontFamily: "goldbold"}} className='text-white text-[3vw] sm:text-[1.8vw] font-bold'>FAQ</span>
      </a>
    </div>
  )
}

export default FixedMenuBar
