const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return '';
  
  // Remove all non-digit characters
  const cleaned = phoneNumber.replace(/\D/g, '');
  
  // Check if the input is valid
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  
  if (match) {
    // Format as (XXX) XXX-XXXX
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }
  
  // Return original input if invalid
  return phoneNumber;
};

export default formatPhoneNumber;
