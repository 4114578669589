import React from 'react'
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

export default function ContactMap({ zoom, height, width, popupText }) {


  const iconPerson = new L.Icon({
    iconUrl: require('../assets/markers/3.png'),
    iconSize: [40, 97], // size of the icon
    shadowSize: [50, 64], // size of the shadow
    iconAnchor: [22, 94], // point of the icon which will correspond to marker's location
    popupAnchor: [-3, -76] // point from which the popup shouåld open relative to the iconAnchor
  });

  return (
    <div className='map' style={{ position: 'relative', zIndex: 0 }}>
      <style>
        {`
          .leaflet-map-pane,
          .leaflet-tile,
          .leaflet-marker-icon,
          .leaflet-marker-shadow,
          .leaflet-tile-pane,
          .leaflet-overlay-pane,
          .leaflet-shadow-pane,
          .leaflet-marker-pane,
          .leaflet-popup-pane,
          .leaflet-control {
            z-index: 1 !important;
          }
          .leaflet-control-container {
            z-index: 2 !important;
          }
        `}
      </style>
      <MapContainer style={{ height: height || 500, width: width || '100%' }} id='mapid' center={[37.782147, -122.398917]} zoom={zoom || 17} scrollWheelZoom={false}>
        <TileLayer
          attribution='&copy; <a href="https://Liveonbeat.com">Live On Beat</a>'
          url="https://api.mapbox.com/styles/v1/djstewie/cm57s12mx00oc01svcop25fjn/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZGpzdGV3aWUiLCJhIjoiY2trcGQ2MW84MDZhNjJ5bzVrbm8xemhycSJ9.y592_-MfDaduUdSMbT66iQ"
        />
        <Marker position={[37.782047, -122.397600]} icon={iconPerson}>
          {popupText && (
            <Popup>
              {popupText}
            </Popup>
          )}
        </Marker>
      </MapContainer>
    </div>
  )
}
