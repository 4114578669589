import React, { useEffect, useState } from "react";
import BasicHeader from "../../components/headers/BasicHeader.js";
import { Get } from "../../api/server.js";
import { useParams } from "react-router-dom";
import PhotoGallery from "../../components/PhotoGallery.js";
import moment from "moment";

function Gallery() {
  const { slug } = useParams();
  const [pageData, setPageData] = useState(null);
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    async function fetchData() {
      const response = await Get(`/galleries/byslug/${slug}`);
      setPageData(response);
      if (response?.items.length > 0) {
        setPhotos(response.items);
      } else {
        setPhotos( response?.images );
      } 
    }
    fetchData();
  }, [slug]);

  return (
    <div>
      <BasicHeader title={pageData?.name} byline={moment(pageData?.gallery_date).format('dddd, MMM D YYYY')} image={"https://sfclubs.s3.us-west-1.amazonaws.com/galleryItems/1741302612162_IMGL0009_Large.jpeg"} />
      <div className="bg-[#101010] flex flex-col justify-center items-center pt-[74px] pb-[162px]">
      {pageData ? (
        <>
          {pageData?.items && (
            <PhotoGallery
              title={pageData?.name}
              date={pageData?.gallery_date}
              // details={data.details}
              images={photos}
              data={pageData}
            />
          )}
        </>
      ) : null}
      </div>
    </div>
  )
}

export default Gallery;
