import React, { useContext } from "react";
import LOGOLIGHT from "../assets/logos/logoLight.svg";
import LOGODARK from "../assets/logos/logoDark.svg";
import GlobalContext from "../contexts/store";

function CompanyLogo({ isDark, size = 75, hiddenOnMobile = false }) {
  const { company } = useContext(GlobalContext);
  return (
    <img
      style={{ height: size }}
      className={`w-auto ${hiddenOnMobile ? "hidden sm:block" : ""}`}
      src={isDark ? company?.logoDark : company?.logoLight}
      alt={company?.name}
    />
  );
}

export default CompanyLogo;
