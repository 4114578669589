import React from 'react'
import headerBg from '../../assets/images/gallery/ritmo/25.jpg'
import DarkGradient from '../DarkGradient'

function BasicHeader({ title, byline, image }) {
  return (
    <div className="relative h-[400px] flex items-center justify-center">
      <img 
        src={image} 
        alt="Header background" 
        className="absolute w-full h-full object-cover object-[50%_50%]"
      />
      <DarkGradient />
      
      <div className={`relative z-10 text-center text-white mt-[${byline ? '100' : '0'}px]`}>
        <h1 className="text-7xl font-bold mb-2">{title}</h1>
        {byline && <span className="text-xl pt-10">{byline}</span>}
      </div>
      <div className="absolute inset-0 bg-black/50"></div>
    </div>
  )
}

export default BasicHeader
