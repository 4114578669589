const menuRoutes = [
  {
    name : 'Home',
    path : '/',
    outside : false,
    exact : true,
  },
  {
    name : 'Gallery',
    path : '/galleries',
    outside : false,
    exact : true,
  },
  {
    name : 'Partnership',
    path : '/partnership',
    outside : false,
    exact : true,
  },
  {
    name : 'Private Events',
    path : 'https://715harrisoneventspace.com',
    outside : true
  },
  {
    name : 'Contact',
    path : '/contact',
    outside : false,
    exact : true,
  }
];

export default menuRoutes;