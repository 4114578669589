import React, { useContext, useEffect, useState } from "react";
import BasicHeader from "../../components/headers/BasicHeader";
import { Col, Row } from "react-bootstrap";
import GalleryItem from "../../components/rj-components/GalleryItem.js";
import SiteButton from "../../components/rj-components/SiteButton.js";
import { Get } from "../../api/server.js";
import { Link, useParams } from "react-router-dom";
import GlobalContext from "../../contexts/store.js";
import { EventTracker } from "../../util/analytics-ag.js";
import moment from "moment";

function GalleryList() {
  const [isMobile, setIsMobile] = React.useState(window.innerWidth <= 768);
  const { org } = useParams();
  const { company, organizations } = useContext(GlobalContext);
  const [galleries, setGalleries] = useState([]);
  const [pageData, setPageData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [orgName, setOrgName] = useState('');
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    async function fetchData() {
      setCurrentPage(1);
      if (company?._id && org) {
        const response = await Get(`/galleries/byCompany/byOrg/${company._id}/${org}?page=${currentPage}`);

        setPageData(response);
        setGalleries(response?.galleries);
      }
    }
    fetchData();
  }, [company._id, org]);

  useEffect(() => {
    setOrgName(organizations.find((o) => o.slug === org)?.name);
  }, [organizations, org]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleNextPage = async () => {
    // scroll to top
    // window.scrollTo(0, 0);
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);
    const response = await Get(`/galleries/byCompany/byOrg/${company._id}/${org}?page=${nextPage}`);
    setGalleries([...galleries, ...response?.galleries]);
  };

  const tracker = (props) => {
    EventTracker({
      category: 'Gallery',
      action: `Clicked on ${props.name} Gallery`,
      label: moment(props.gallery_date).format('ll')
    })
  }

  return (
    <div>
      <BasicHeader title={`${orgName || ''} Gallery`} image={'https://sfclubs.s3.us-west-1.amazonaws.com/galleryItems/1741302612162_IMGL0009_Large.jpeg'} />
      <div className="bg-[#101010] flex flex-col justify-center items-center pt-[74px] pb-[162px]">
        {
          galleries.reduce((rows, event, index) => {
            if (index % 3 === 0) rows.push([]); // Start a new row every 3 events
            rows[rows.length - 1].push(event); // Add event to the current row
            return rows;
          }, [])
            .map((row, rowIndex) => (
              <Row key={rowIndex} className="md:mb-[90px]">
                {row.map((gallery) => (
                  <Col
                    key={gallery.id}
                    lg={4}
                    className="d-flex justify-content-center px-0"
                  >
                    <Link to={`/galleries/${org}/${gallery.slug}`} onClick={tracker({ name: gallery?.name, gallery_date: moment(gallery?.gallery_date).format('ll') })}>
                      <GalleryItem
                        eventImage={gallery?.cover || gallery?.images[0]?.image_url || ''}
                        eventDate={gallery?.gallery_date}
                        eventName={gallery?.name}
                      />
                    </Link>
                  </Col>
                ))}
              </Row>
            ))

        }

        {pageData && currentPage <= pageData.totalPages - 1 ? (
          <SiteButton
            buttonText={'Load More'}
            className="max-w-[171px] text-small py-[13px] px-[30px] max-h-[40px]"
            onClick={handleNextPage}
          />
        ) : (
          <h3 className="text-sm font-bold mb-2 text-center text-white">No More Galleries at this time for {orgName}. Check back later!</h3>
        )}
      </div>
    </div>
  );
}

export default GalleryList;
