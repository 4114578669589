import React from "react";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";

export default function SocialMediaWrapper() {
  return (
    <div className="flex text-white">
      <FaFacebook className="m-1" />
      <FaInstagram className="m-1" />
      <FaTwitter className="m-1" />
      <FaTiktok className="m-1" />
    </div>
  );
}
