import React, { useContext, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import VIDEO from '../../assets/videos/firstAndBell.mp4';
import DarkGradient from '../DarkGradient';
import SliderContent from './SliderContent';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import { Col, Row } from 'react-bootstrap';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import GlobalContext from '../../contexts/store';

function LandingHeader() {
  const { events } = useContext(GlobalContext);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  
  return (
    <div className='relative justify-center items-center h-screen w-full overflow-hidden max-h-[720px]'>
      <video
        autoPlay
        loop
        muted
        playsInline
        className='absolute top-0 left-0 w-full h-full object-cover z-0'
      >
        <source src={'https://sfclubs.s3.us-west-1.amazonaws.com/videos/newIntro.mov'} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <DarkGradient />
      <div className='relative z-10'>
        <Row>
          <Col md={12} className={`${isMobile ? 'pt-[100px]' : 'pt-[150px]'} flex items-center justify-center`}>
            <button className="absolute left-4 z-20 top-1/2 transform -translate-y-1/2 ml-5 md:ml-20">
              <div className="prev-button p-2 rounded-full bg-white">
                <FaArrowLeft size={20} color='black' />
              </div>
            </button>
            <button className="absolute right-4 z-20 top-1/2 transform -translate-y-1/2 mr-5 md:mr-20">
              <div className="next-button p-2 rounded-full bg-white">
                <FaArrowRight size={20} color='black' />
              </div>
            </button>

            <Swiper
              slidesPerView={1}
              spaceBetween={500}
              loop
              modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
              navigation={{
                prevEl: '.prev-button',
                nextEl: '.next-button',
              }}
              pagination={{ clickable: true }}
              scrollbar={{ draggable: true }}
              autoplay={{
                delay: 5000, // 5 seconds
                disableOnInteraction: false, // continues autoplay after user interaction
              }}
            >
              {events.map((event) => (
                <SwiperSlide key={event._id}>
                  <SliderContent event={event} />
                </SwiperSlide>
              ))}
            </Swiper>
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default LandingHeader
