import React, { useContext } from "react";
import ContactMap from "../ContactMap";
import GlobalContext from "../../contexts/store";
import formatPhoneNumber from "../../helpers/phoneNumberFormatter";

export default function ContactAddress({isMobile}) {
  const { company } = useContext(GlobalContext);
  return (
    <div className="bg-[#101010] flex justify-center items-center flex-col">
       <ContactMap zoom={14} height={isMobile ? 247 : 247} width={isMobile ? 350 : 441} popupText={`${company?.name} is located at ${company?.address}, ${company?.city}, ${company?.state} ${company?.zip}.`} />
      <div className="text-center">
        <p className="text-[16px] mt-[10px] text-white">
          You can contact us directly at <br />
          {company?.email} or {formatPhoneNumber(company?.phone)}
        </p>
        <p className="text-white">
          Our address is <strong>{company?.address}, {company?.city}, {company?.state} {company?.zip}.</strong>
        </p>
      </div>
    </div>
  );
}
