import React, { useContext, useRef, useState } from 'react'
import { Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { FiMinusCircle } from 'react-icons/fi';
import { MdArrowBack, MdOutlineAddCircle } from 'react-icons/md';
import stripePaymentModthod from '../assets/images/paymentMethods.png';
import {
  CardElement,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import ApplePay from './ApplePay';
import buildTicketFees from '../helpers/buildTicketFees';
import usdFormatter from '../helpers/usdFormatter';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { AffContext } from '../contexts/AffiliateContext';
import Ribbon from './Ribbon';
import { Post } from '../api/server';
import Button from './Button';
// import Ticket from './Ticket';

function TicketCart({ show, handleClose, currentTicket, company, ticketQuantities, setTicketQuantities, handleShow, event, affId }) {
  const { aff } = useContext(AffContext);
  const navigate = useNavigate();
  const modalContentRef = useRef(null);
  const [inputValues, setInputValues] = useState({});
  const stripe = useStripe();
  const elements = useElements();
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [doseAgree, setDoseAgree] = useState(false);


  const scrollToTop = () => {
    if (modalContentRef.current) {
      modalContentRef.current.scrollTo(0, 0);
    }
  };

  const handleAgree = () => {
    setDoseAgree(!doseAgree);
  }

  const handleNext = () => {
    // scroll to top
    if (doseAgree === false) return toast.warning('You have NOT been charged! Please agree to the Terms and Conditions before proceeding');
    scrollToTop();
    setStep(step + 1);
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setInputValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  }

  const handleDecreaseQuantity = (ticketName) => {
    ticketName = ticketName.ticketName;
    const newQuantity = (ticketQuantities[ticketName] || 0) - 1;
    setTicketQuantities((prevQuantities) => ({
      ...prevQuantities,
      [ticketName]: newQuantity >= 0 ? newQuantity : 0,
    }));
  };

  const handleIncreaseQuantity = (ticket) => {
    const getMaxUserQuantity = ticket?.maxPurchaseQuantity || 10;
    const maxQuantity = ticket.totalQuantity - ticket.soldQuantity;
    const ticketName = ticket.ticketName;
    const currentQuantity = ticketQuantities[ticketName] || 0;
    const newQuantity = Math.min(currentQuantity + 1, maxQuantity, getMaxUserQuantity);
    setTicketQuantities((prevQuantities) => ({
      ...prevQuantities,
      [ticketName]: newQuantity,
    }));
  };


  const calculateTotalPrice = () => {// Stripe's fixed fee
    let events = [];
    for (const ticketName in ticketQuantities) {
      const quantity = ticketQuantities[ticketName];
      if (quantity > 0) {
        const ticket = currentTicket?.ticketinventory?.find(
          (ticket) => ticket.ticketName === ticketName
        );
        const price = ticket?.price || 0;
        for (let i = 0; i < quantity; i++) {
          price > 0 && events.push({
            ticketName: ticketName,
            price: price,
            extraFee: ticket?.extraFee || 0,
          })
        }
      }
    }
    return { events };
  };


  const { events } = calculateTotalPrice();
  const doseCost = events.reduce((acc, event) => acc + event.price, 0) > 0;

  const finalAmounts = buildTicketFees(events, company?.application_fee, 0.029, 0.3);
  const finalAmount = finalAmounts.total;
  const cardElementStyle = {
    base: {
      fontSize: '16px',
      color: '#424770',
      '::placeholder': {
        color: '#aab7c4',
      },
      lineHeight: '25px',
    },
    invalid: {
      color: '#9e2146',
    },
  };

  const payBill = async (e) => {
    e.preventDefault();
    if (!doseAgree) return toast.warning('You have NOT been charged! Please agree to the Terms and Conditions before proceeding');
    if (!inputValues.first_name || inputValues.first_name.length < 1) return toast.warning('Please enter your first name');
    if (!inputValues.last_name || inputValues.last_name.length < 1) return toast.warning('Please enter your last name');
    if (!inputValues.phone || inputValues.phone.length < 1) return toast.warning('Please enter your phone number');
    if (!inputValues.email || inputValues.email.length < 1) return toast.warning('Please enter your email');

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      return;
    }

    setIsLoading(true);

    if (doseCost) {
      const cardElement = elements.getElement(CardElement);

      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: `${inputValues.first_name} ${inputValues.last_name}`,
          email: inputValues.email,
          phone: inputValues.phone,
        },
      });

      if (error) {
        setIsLoading(false);
        console.log('[error]', error);
        toast.error(error.message);
        return;
      }

      Swal.fire({
        title: "Processing Transaction ",
        html: "Please wait while we process your payment",
        background: "black",
        color: "white",
        allowEscapeKey: false,
        allowEnterKey: false,
        allowOutsideClick: () => !Swal.isLoading(),
        didOpen: () => {
          Swal.showLoading();
          Post(`/events/buyTickets/${currentTicket._id}`, {
            token: doseCost ? paymentMethod : null,
            company: company._id,
            first_name: inputValues.first_name,
            last_name: inputValues.last_name,
            email: inputValues.email,
            phone: inputValues.phone,
            tickets: ticketQuantities,
            amountDue: finalAmount,
            affId: affId
          }).then((response) => {
            Swal.close();
            setIsLoading(false);
            toast.success('Ticket(s) has been purchased successfully');
            navigate(`/events/confirmation/${response.user}/${response.event}`)
            handleClose();
          }).fail((error) => {
            console.log(error)
            Swal.close();
            setIsLoading(false);
            toast.error(error?.responseJSON?.payment_intent?.last_payment_error?.message || 'Something went wrong on our end. Ticket(s) has not been purchased. Please try again or contact support.');
          });
        },
      });

    } else {
      Swal.fire({
        title: "Processing Transaction ",
        html: "Please wait while we process this free transaction",
        background: "black",
        color: "white",
        allowEscapeKey: false,
        allowEnterKey: false,
        allowOutsideClick: () => !Swal.isLoading(),
        didOpen: () => {
          Swal.showLoading();
          Post(`/events/buyTickets/${currentTicket._id}`, {
            token: null,
            company: company._id,
            first_name: inputValues.first_name,
            last_name: inputValues.last_name,
            email: inputValues.email,
            tickets: ticketQuantities,
            amountDue: finalAmount,
            affId: affId
          }).then((response) => {
            Swal.close();
            setIsLoading(false);
            toast.success('Ticket(s) has been purchased successfully');
            navigate(`/events/confirmation/${response.user}/${response.event}`)
            handleClose();
          }).fail((error) => {
            console.log(error)
            Swal.close();
            setIsLoading(false);
            toast.error(error?.responseJSON?.payment_intent?.last_payment_error?.message || 'Something went wrong on our end. Ticket(s) has not been purchased. Please try again or contact support.');
          });
        },
      });
    }




  }

  const Totals = () => {

    return (
      <div className="">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center mb-4">
            <img
              src={currentTicket?.promo?.banner || currentTicket?.promo?.post || currentTicket?.promo?.story}
              className="img-fluid"
              style={{ width: '100%' }}
              alt="Avatar"
            />
          </div>

          <p className="small mb-2">Order Summary</p>
          <ul className="list-group list-group-flush">
            {Object.keys(ticketQuantities).map((ticketName, index) => {
              const quantity = ticketQuantities[ticketName] || 0;
              if (quantity > 0) {
                const ticket = currentTicket?.ticketinventory?.find(
                  (ticket) => ticket.ticketName + index === ticketName + index
                );
                const price = ticket?.price || 0;
                const totalPrice = quantity * price;

                return (
                  <li
                    key={index}
                    className="list-group-item d-flex justify-content-between align-items-center border-0 px-0 pb-0 text-black"
                  >
                    {quantity}x {ticketName}
                    <span className="pull-right">${totalPrice.toFixed(2)}</span>
                  </li>
                );
              } else {
                return null;
              }

            })}
          </ul>
          <hr className="my-4" />

          <div className="d-flex justify-content-between">
            <p className="mb-2">Subtotal</p>
            <p className="mb-2">{usdFormatter(finalAmounts.breakdown.tickets)}</p>
          </div>

          <div className="d-flex justify-content-between">
            <p className="mb-2">Fees</p>
            <p className="mb-2">
              {finalAmount > 0.31 ? usdFormatter(finalAmount - finalAmounts.breakdown.tickets) : '$0.00'}
            </p>
          </div>

          <div className="d-flex justify-content-between mb-4">
            <p className="mb-2">Total Due</p>
            <p className="mb-2">{finalAmount > 0.31 ? usdFormatter(finalAmount) : '$0.00'}</p>
          </div>
          {step === 1 && (
            <div style={{ marginTop: 50, zIndex: '9999' }} className='event-pay-buttons'>
              <div className='flex items-center gap-2' style={{ marginBottom: 10 }}>
                <Form.Check // prettier-ignore
                  type="switch"
                  required
                  defaultChecked
                  checked={doseAgree}
                  onChange={handleAgree}
                />
                <span className='agree-switch-text'> I agree to the <Link to='/terms' target='_blank'>Terms and Conditions</Link></span>
              </div>
              <Button
                style={{ width: '100%', marginBottom: 10 }}
                text={doseCost ? usdFormatter(finalAmount) + ' Checkout' : '$0.00 Checkout'}
                black={true}
                outline={true}
                onClick={() => {
                  if (Object.values(ticketQuantities).some(quantity => quantity > 0)) {
                    handleNext();
                  } else {
                    toast.warning('Please select at least one ticket');
                  }
                }}
              />
              {doseCost && (
                <ApplePay
                  terms={doseAgree}
                  ticketQuantities={ticketQuantities}
                  totalAmountDue={finalAmount}
                  event={event}
                  handleClose={handleClose}
                  currentTicket={currentTicket}
                  history={navigate}
                  affId={affId}
                />
              )}
            </div>
            // {finalAmount > 0.30 ? usdFormatter(finalAmount) : '0.00'}
          )}
          {step === 2 && (
            <Row>
              <Col xs={12}>
                {isLoading ? (
                  <Button text={isLoading ? 'Processing...' : 'Pay'} outline={true} black={true} style={{width: '100%'}} disabled={isLoading} className="btn btn-block w-full mt-1" />
                ) : (
                  <Button text={isLoading ? 'Processing...' : 'Pay'} onClick={payBill} outline={true} black={true} style={{width: '100%'}} disabled={isLoading} className="btn btn-block w-full mt-1" />
                )}



              </Col>
            </Row>
          )}
        </div>
      </div>
    )
  }

  const CartScreen = () => {
    return (
      <Row id="ticket-cart">
        <div className="col-lg-7">
          <h5 className="mb-3 margin-top-30">
            <a href="#!" onClick={handleClose} className="text-body flex items-center">
              <MdArrowBack /> Continue shopping
            </a>
          </h5>
          <hr />

          <div className="d-flex justify-content-between align-items-center mb-4">
            <div>
              <p className="mb-1">Available Tickets</p>
              <p className="mb-0">
                There are{' '}
                <b>
                  {currentTicket?.ticketinventory && Array.isArray(currentTicket?.ticketinventory)
                    ? currentTicket?.ticketinventory.length
                    : 0}
                </b>{' '}
                ticket group(s) for you to choose from.
              </p>
            </div>
          </div>

          <Row>
            {currentTicket?.ticketinventory && Array.isArray(currentTicket?.ticketinventory)
              ? currentTicket?.ticketinventory?.map((ticket, index) => {
                const quantity = ticketQuantities[ticket.ticketName] || 0;
                const ticketPrice = ticket?.price || 0;
                const affiliateSlug = ticket?.affiliate?.slug || null;

                const { total } = buildTicketFees([ticket], company?.application_fee, 0.029, 0.3);

                // show all tickets without affiliate and don't show ticket unless it's the affiliate's ticket
                if (affiliateSlug && aff !== affiliateSlug) {
                  return null;
                }

                return (
                  <Col className="ticketCard" key={index} xs={12}>
                    <Card>
                      <Card.Header>
                        {ticket?.isBestValue && <Ribbon title={ticket?.valueTitle} />}
                        <Row>
                          <Col xs={6}>
                            <div style={{ fontFamily: 'goldbold' }} className="text-black ticketCartTicketName text-[24px] uppercase">{ticket.ticketName}</div>
                            <span className="ticket-price">{usdFormatter(ticketPrice)}</span>
                          </Col>
                          <Col className='flex justify-end' style={{ zIndex: 110 }} xs={6}>
                            <div style={{ marginRight: 2 }} className="pull-right flex justify-center items-center">
                              {ticket.soldQuantity >= ticket.totalQuantity ? (
                                <>
                                  <h4 style={{ marginTop: 10, fontFamily: 'goldbold', fontSize: 20, fontWeight: 800 }} className="text-danger">Sold Out</h4>
                                </>
                              ) : (
                                <div className='flex items-center gap-2 mt-[10px] mr-[20px]'>
                                  <FiMinusCircle
                                    size={28}
                                    className="pointer"
                                    color="black"
                                    onClick={() => handleDecreaseQuantity(ticket)}
                                  />
                                  <div className="quantity text-black mt-0">{quantity}</div>
                                  <MdOutlineAddCircle
                                    size={28}
                                    className="pointer"
                                    color="black"
                                    onClick={() => handleIncreaseQuantity(ticket)}
                                  />
                                </div>
                              )}

                            </div>
                          </Col>
                        </Row>
                      </Card.Header>
                      <Card.Body>
                        <Card.Text style={{ padding: '0 15px' }}>
                          <span className="ticket-fee">
                            <font style={{ fontWeight: 600 }}><font style={{ color: 'red' }}>*</font>{usdFormatter(total || 0)}</font> After fee's per ticket
                          </span>
                          <br />
                          {<span style={{ marginTop: 0 }} className="ticket-end-date">{ticket?.ticketDescription}</span>}
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                );
              })
              : null}
          </Row>
        </div >
        <Col lg={5}>
          <Totals />
        </Col>
      </Row >
    )
  }

  return (
    <>
      <Button text="Purchase Tickets" onClick={handleShow} variant="primary mt-2" black={false} outline={true} style={{width: '75%', padding: '20px 0'}} />
      <Modal

        dialogClassName="modal-90w"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="xl"
        contentClassName="ticket-modal"
      >
        <Modal.Body style={{ marginBottom: 80 }} ref={modalContentRef}>
          {step === 1 && (
            <>
              <CartScreen />
            </>
          )}
          {step === 2 && (
            <Row style={{ margin: 10 }} className='checkout-page'>
              <Col md={7}>
                <Row>
                  <Col md={12}>
                    <h5 className="mb-3">
                      <a href="#!" onClick={handlePrevious} className="text-body flex items-center">
                        <MdArrowBack /> Back to Cart
                      </a>
                    </h5>
                    <hr />
                    <h3 className="text-black">Billing Information</h3>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="first_name">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control className='customInput' disabled={isLoading} name="first_name" onChange={(e) => handleInputChange(e)} type="text" placeholder="First Name" value={inputValues.first_name} />
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="last_name">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control disabled={isLoading} name="last_name" onChange={(e) => handleInputChange(e)} type="text" placeholder="Last Name" value={inputValues.last_name} />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="email">
                      <Form.Label>Phone</Form.Label>
                      <Form.Control disabled={isLoading} name="phone" onChange={(e) => handleInputChange(e)} type="phone" placeholder="Enter Phone Number" value={inputValues.phone} />
                      <Form.Text className="text-muted">
                        We'll never share your email with anyone else.
                      </Form.Text>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3" controlId="email">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control disabled={isLoading} name="email" onChange={(e) => handleInputChange(e)} type="email" placeholder="Enter email" value={inputValues.email} />
                      <Form.Text className="text-muted">
                        We'll never share your number with anyone else.
                      </Form.Text>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  {doseCost && (
                    <>
                      <Col md={12}>
                        <h3 className='text-black mt-20'>Payment Information</h3>
                        <div className="card-element-container">
                          <Form.Control
                            disabled={isLoading}
                            as={CardElement}
                            options={{ style: cardElementStyle }}
                          />
                        </div>
                      </Col>
                      <Col className='text-center items-center flex flex-col justify-center' md={12}>
                        <div className="flex justify-center">
                          <img className='margin-top-20' src={stripePaymentModthod} width={150} alt="methods" />
                        </div>
                        <div className="text-center">
                          <p>
                            <a href='https://stripe.com/legal/ssa' target='_blank' rel='noreferrer'>
                              Stripe Terms and Conditions
                            </a>
                          </p>
                        </div>
                      </Col>
                    </>
                  )}
                </Row>
              </Col>
              <Col md={5}>
                <Totals />
              </Col>
            </Row>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default TicketCart