import React from "react";

export default function GalleryItem({ eventImage, eventDate, eventName }) {
  const date = new Date(eventDate);
  const options = { month: "long", weekday: "long", day: "numeric" };
  const formattedDate = date.toLocaleDateString("en-US", options);
  const [weekday, month, numericDay] = formattedDate.split(" ");

  return (
    <div className="relative w-[343px] h-[313px] m-3.5">
      {/*  sm:w-24 sm:h-24 md:w-32 md:h-32 lg:w-72 lg:h-72 ^^^ */}
      <img
        src={eventImage}
        alt="Event"
        className="w-full h-full object-cover"
      />
      <div 
        style={{ 
          background: 'linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0.2), rgba(0,0,0,0.1))'
        }} 
        className="absolute inset-0"
      ></div>
      <div className="absolute inset-0 text-white bg-black bg-opacity-50 flex flex-col items-center pt-[63px] pb-[20px]">
        <span className="text-[30px] font-medium leading-none">{month}</span>
        <span className="text-[89px] font-semibold leading-none">
          {numericDay}
        </span>
        <span className="text-3xl font-extralight leading-snug">
          {weekday.slice(0, -1)}
        </span>
        <span className="text-xl font-semibold leading-tight mt-[30px] text-center">
          {eventName}
        </span>
      </div>
    </div>
  );
}
