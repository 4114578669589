import {Get} from '../api/server';

async function getCompany () {
    let address = window.location.hostname;

    return await Get('/companies/getByAddress/' + address).then(res => {
        return res;
    }).catch(err => {
        return null;
    });
}

export default getCompany;