import ReactGA from 'react-ga';

export default function analytics() {
    ReactGA.initialize('UA-243653213-1');
    // Track initial pageview
    logPageView();
    return;
}

export const logPageView = () => {
    ReactGA.set({ page: window.location.pathname + window.location.search });
    ReactGA.pageview(window.location.pathname + window.location.search);
};

export const EventTracker = ({category, action, label}) => {
    ReactGA.initialize('UA-243653213-1');
    ReactGA.event({
        category: category,
        action: action,
        label: label
    });
}
