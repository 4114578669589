import React from 'react';
import { Row, Col } from 'react-bootstrap';
import LightGallery from 'lightgallery/react';
import lgShare from 'lightgallery/plugins/share';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';

const PhotoGallery = ({ title, details, images, date, data }) => {
  const onInit = () => {
    console.log('lightGallery has been initialized');
  };

  return (
    <div className="container mx-auto px-4">
      <Row>
        <Col className="mb-8" md={12}>
          <LightGallery
            onInit={onInit}
            speed={500}
            plugins={[lgThumbnail, lgZoom, lgShare]}
            elementClassNames="grid grid-cols-1 md:grid-cols-3 gap-4"
            share={{
              facebook: true,
              twitter: true,
              instagram: true
            }}
          >
            {images.length > 0 && images.map((item, key) => (
              <a 
                key={key}
                className="block relative aspect-square overflow-hidden" 
                href={data?.hasWatermark ? item?.image_watermarked_url : item?.image_url || item}
                data-lg-size="1400-1400"
              >
                <img
                  alt="Club X"
                  src={data?.hasWatermark ? item?.image_watermarked_url : item?.image_url || item}
                  className="w-full h-full object-cover absolute inset-0 rounded"
                />
              </a>
            ))}
          </LightGallery>
        </Col>
      </Row>
    </div>
  );
};

export default PhotoGallery;
