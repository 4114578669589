import React, { useContext, useEffect, useState } from "react";
import { CompanyContext } from "../contexts/CompanyContaxt";
import { toast } from "react-toastify";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Get, Post } from "../api/server";
import GlobalContext from "../contexts/store";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const Unsubscribe = () => {
  const { company } = useContext(GlobalContext);
  const [email, setEmail] = useState("");
  const [unsubscribed, setUnsubscribed] = useState(false);
  const [orgs, setOrgs] = useState([]);
  // get size of the window width
  // eslint-disable-next-line no-unused-vars
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    if (!company?._id) return;
    async function fetchData() {
      const res = await Get(`/events/getPromoBanners/${company?._id}`);
      console.log('res', res);
      const data = await res.map((event) => event.promo);
      setOrgs(data);
    }
    fetchData();
  }, [company?._id]);


  const handleUnsubscribe = (e) => {
    e.preventDefault();
    if (!email) return toast.error("Please enter your email address.");

    Post("/emailBlasts/unsubscribe", {
      email,
      company: company?._id,
    })
      .then((res) => {
        if (res.status === 404) return alert("Email not found. Try another email or check the spelling.");

        setUnsubscribed(true);
        toast.success("You have been unsubscribed.");
      })
      .catch((err) => {
        setEmail(null);
        toast.error("Code 404: Email not found. Try another email or check the spelling.");
      });
  };

  return (
    <>
      <Swiper
        spaceBetween={10}
        slidesPerView={windowWidth < 768 ? 2 : 5}
        speed={1000} // Slower transition speed
        autoplay={{
          delay: 0, // No delay between transitions
          disableOnInteraction: false,
          pauseOnMouseEnter: false,
        }}
        loop={true}
        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
        style={{
          marginTop: "75px",
        }}
      >
        {orgs.map((org) => (
          <SwiperSlide key={org._id}>
            <img src={org?.story} alt={org.name} style={{ minWidth: "100%", height: "50vh", objectFit: "cover" }} className="w-full object-cover" />
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="absolute top-0 left-0 w-full z-10 h-[50vh] mt-[75px] bg-gradient-to-t from-black to-transparent"></div>
      <div className="absolute top-0 left-0 w-full z-10 h-[50vh] mt-[75px] text-white text-center flex items-center justify-center">
        <h1 className="font-bold text-[8vw]">Unsubscribe</h1>
      </div>

      <Container className="mt-5 mb-[300px]">
        <Row className="justify-content-center text-center">
          <Col md={6}>
            {unsubscribed ? (
              <div>
                <h1 style={{ fontSize: "2.5rem", color: "#dc3545" }}>
                  😢 You'll be missed... 😢
                </h1>
                <p>
                  You’ve been unsubscribed. 😭
                </p>
                <Button as={Link} variant="danger" to="/">
                  Go Home
                </Button>
              </div>
            ) : (
              <div>
                <h1 style={{ fontSize: "2.5rem", color: "#dc3545" }}>
                  Wait! Are you sure? 😟
                </h1>
                <p className="text-center text-white">We’ll miss you... but if you must go, enter your email below to unsubscribe:</p>
                <Form className="text-center mt-20" onSubmit={handleUnsubscribe}>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Control
                      type="email"
                      placeholder="Enter your email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      style={{
                        padding: "10px",
                        fontSize: "1.2rem",
                        marginBottom: "20px",
                        color: "white",
                      }}
                    />
                  </Form.Group>
                  <Button variant="danger" type="submit" size="lg" block>
                    Yes, unsubscribe me 😢
                  </Button>
                </Form>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Unsubscribe;
