import React, { useContext, useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap';
import EventCard from '../rj-components/EventCard';
import Button from '../Button';
import { Link } from 'react-router-dom';
import GlobalContext from '../../contexts/store';

function UpcomingEvents({ id, isDark = false, excludeEvents=[], onlyShow, title, org, ownEvents }) {
  const { events, organizations } = useContext(GlobalContext);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [limit, setLimit] = useState(6);
  const [orgsWithEvents, setOrgsWithEvents] = useState([]);


  // create toggle for 6 and 20
  const toggleLimit = () => {
    setLimit(limit === 6 ? 20 : 6);
  }


  let filteredEvents = org?.events || ownEvents;
  // if (onlyShow) {
  //   filteredEvents = filteredEvents.slice(0, onlyShow);
  // }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      {filteredEvents.length > 0 && (
        <div id={id} className={`pt-0 ${isMobile ? 'px-5 items-center mt-[150px]' : 'px-[190px] mt-[100px]'} flex flex-col justify-center min-h-[700px]`}>
          <div className={`flex ${isMobile ? 'flex-col gap-5' : 'items-center justify-between'} mb-10`}>
            <h1 className={`text-7xl font-bold ${isDark ? 'text-white' : ''}`}>{title || 'UPCOMING EVENTS'}</h1>
          </div>
          <Row>
            {filteredEvents.slice(0, limit).map((event, key) => (
              <Col
                key={event._id}
                md={4}
                className="te"
              >
                <Link key={key} to={`/events/event/${event.slug}`}>
                  <EventCard
                    event={event}
                  />
                </Link>
              </Col>
            ))}
            {filteredEvents?.length > 6 && (
              <div className='flex justify-center items-center'>
                <Button black={false} outline={true} onClick={() => toggleLimit()} text="Show More" />
              </div>
            )}
          </Row>
        </div>
      )}
    </>
  )
}

export default UpcomingEvents
