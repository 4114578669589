import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import GlobalContext from "../contexts/store";
import { useContext } from "react";

function Terms() {
  const { company } = useContext(GlobalContext);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className="bg-white">
      <header className="h-[84px] bg-black"></header>
      <article className="p-[20px_40px_30px] md:p-[74px_177px_139px]">
        {/* SEO Title */}
        <h1
          style={{ fontFamily: "dm_sans", fontWeight: "bold" }}
          className="font-bold text-[64px] mb-[32px]"
        >
          Terms & Conditions
        </h1>

        {/* Welcome Section */}
        <section aria-labelledby="welcome-section" className="text-[16px]">
          <h2 id="welcome-section" className="font-bold text-[20px] m-0">
            Welcome to {company?.name}
          </h2>
          <p className="font-medium text-[16px] mt-0">
            Thank you for your interest in our events and venue rentals. Before
            proceeding with your ticket purchase or rental agreement, please
            carefully read the following terms and conditions, which govern
            ticket sales and venue rentals provided by {company?.name} (referred to
            as "the Venue"). General
          </p>
          <p className="font-medium text-[16px]">
            The terms "you" and "your" refer to the ticket purchaser or renter.
            "We," "us," "our," and "{company?.name}" refer to the operators of the
            Venue. By purchasing tickets or renting the Venue through
            {company?.urls?.[0]}, you agree to these terms, which form a binding
            legal agreement. If you do not agree with any part of these terms,
            you should not proceed with your purchase or rental agreement.
          </p>
        </section>

        {/* Ticket Purchase Section */}
        <section aria-labelledby="ticket-purchase-section">
          <h2
            id="ticket-purchase-section"
            className="font-bold text-[20px] m-0"
          >
            Ticket Purchase
          </h2>
          <p className="font-medium text-[16px] my-0">
            Tickets sold through {company?.urls?.[0]} grant you a revocable license
            to attend the event. This license can be revoked by {company?.name} at
            any time without compensation if you violate any term of this
            agreement.
          </p>
          <ul className="font-medium text-[16px] mt-0 list-disc">
            <li>
              You must provide your first name, phone number, and email address
              for ticket processing and information.
            </li>
            <li>
              All ticket purchases are final. No refunds, exchanges, or
              cancellations are allowed.
            </li>
            <li>
              Tickets must be obtained from {company?.urls?.[0]} or authorized
              partners. Tickets from unauthorized sources may be invalid, lost,
              stolen, or counterfeit, and if so, are void.
            </li>
          </ul>
        </section>

        {/* Venue Rentals Section */}
        <section aria-labelledby="venue-rentals-section">
          <h2 id="venue-rentals-section" className="font-bold text-[20px] m-0">
            Venue Rentals
          </h2>
          <p className="font-medium text-[16px] my-0">
            For those interested in renting our space for private or corporate
            events:
          </p>
          <ul className="font-medium text-[16px] mt-0 list-disc">
            <li>
              A rental agreement must be signed, detailing the event date, time,
              and any additional services required.
            </li>
            <li>
              A non-refundable deposit is required to secure your booking.
            </li>
            <li>
              Full payment must be made at least 14 days before your event date.
            </li>
          </ul>
        </section>

        {/* Data and Privacy Section */}
        <section aria-labelledby="data-privacy-section">
          <h2 id="data-privacy-section" className="font-bold text-[20px] m-0">
            Data and Privacy
          </h2>
          <p className="font-medium text-[16px] my-0">
            We collect your personal information to process ticket purchases or
            venue rentals and to inform you of updates or changes related to
            your event.
          </p>
          <ul className="font-medium text-[16px] mt-0 list-disc">
            <li>Your personal data will not be sold to third parties.</li>
            <li>
              Payment processing is securely handled by Stripe; we do not store
              or have access to your credit card details.
            </li>
          </ul>

          {/* Refunds Policy */}
          <h2 className="font-bold text-[20px] m-0">No Refunds Policy</h2>
          <p className="font-medium text-[16px] my-0">All sales are final.</p>
          <ul className="font-medium text-[16px] my-0 list-disc">
            <li>
              For events: Once an event has commenced, we will not provide
              refunds, credits, or exchanges for any reason, including event
              cancellation, postponement, or dissatisfaction.
            </li>
            <li>
              For rentals: If you cancel your venue rental, your deposit will
              not be refunded.
            </li>
          </ul>
          <p className="font-medium text-[16px] mt-0">
            If an event or rental is canceled by {company?.name} and not rescheduled,
            we may, at our discretion, provide a refund for the face value of
            the ticket or rental fee.
          </p>
        </section>

        {/* Conduct Section */}
        <section aria-labelledby="conduct-section">
          <h2 id="conduct-section" className="font-bold text-[20px] m-0">
            Conduct at Events
          </h2>
          <p className="font-medium text-[16px] my-0">
            You agree to follow all rules and regulations of the Venue and
            behave in a manner that does not disrupt the enjoyment of others.
          </p>
          <ul className="font-medium text-[16px] mt-0 list-disc">
            <li>
              {" "}
              We reserve the right to remove any attendee or renter for failure
              to adhere to the Venue rules or for engaging in disruptive,
              illegal, or offensive behavior without refund or compensation.
            </li>
          </ul>
        </section>

        {/* Limitation of Liability */}
        <section aria-labelledby="limitation-liability-section">
          <h2
            id="limitation-liability-section"
            className="font-bold text-[20px] m-0"
          >
            Limitation of Liability
          </h2>
          <p className="font-medium text-[16px] my-0">
            First&Bell is not responsible for personal damages, losses, or
            injuries sustained at the Venue.
          </p>
          <ul className="font-medium text-[16px] mt-0 list-disc">
            <li>
              This includes, but is not limited to, lost, stolen, or damaged
              property.
            </li>
            <li>
              You attend events or rent the Venue at your own risk,
              understanding that certain inherent risks are involved in live
              events.
            </li>
          </ul>
        </section>

        {/* Changes to Terms */}
        <section aria-labelledby="changes-terms-section">
          <h2 id="changes-terms-section" className="font-bold text-[20px] m-0">
            Changes to Terms
          </h2>
          <p className="font-medium text-[16px] mt-0">
            We reserve the right to change these terms at any time without prior
            notice. Updated terms will be posted on {company?.urls?.[0]}. It is your
            responsibility to review these terms before purchasing tickets or
            entering into a rental agreement.
          </p>
        </section>

        {/* Governing Law */}
        <section aria-labelledby="governing-law-section">
          <h2 id="governing-law-section" className="font-bold text-[20px] m-0">
            Governing Law
          </h2>
          <p className="font-medium text-[16px] mt-0">
            These terms are governed by the laws of the state of {company?.state},
            without regard to its conflict of law provisions.
          </p>
        </section>

        {/* Dispute Resolution */}
        <section aria-labelledby="dispute-resolution-section">
          <h2
            id="dispute-resolution-section"
            className="font-bold text-[20px] m-0"
          >
            Dispute Resolution
          </h2>
          <p className="font-medium text-[16px] mt-0">
            In the event of a dispute, an independent third-party arbiter may be
            consulted to resolve the issue. The arbiter's decision will be final
            and binding.
          </p>
        </section>

        {/* Contact Us */}
        <section aria-labelledby="contact-us-section">
          <h2 id="contact-us-section" className="font-bold text-[20px] m-0">
            Contact Us
          </h2>
          <p className="font-medium text-[16px] my-0">
            For questions or concerns about these terms, please contact us at{" "}
            <a
              className="font-bold italic no-underline text-black"
              href={`mailto:${company?.email}`}
            >
              {company?.email}
            </a>
          </p>
          <p className="font-medium text-[16px]">
            We look forward to hosting your next memorable event!
          </p>
          {/*  */}
          <p className="font-medium text-[16px] mb-0">
            Get your tickets or book your event now at 
            <Link
              className="text-black no-underline border-b-2 border-black"
              to={"/"}
            >
              {company?.urls?.[0]}
            </Link>
            .
          </p>
          <p className="font-medium text-[16px] mt-0">
            Address: {company?.address}, {company?.city}, {company?.state} {company?.zip}
          </p>
        </section>
      </article>
    </main>
  );
}

export default Terms;
