import React, { useEffect, useState } from 'react'
import DarkGradient from '../DarkGradient'

function ImageAd({ id, image, title, text, buttonLink, buttonText = 'Learn More' }) {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div id={id} style={{}} className="relative h-[383px] flex">
      <img 
        src={image} 
        alt="Header background" 
        className="absolute w-full h-full object-cover object-[50%_50%]"
      />
      <DarkGradient />
      <div className={`relative z-10 ${isMobile ? 'px-7' : 'pl-20 pt-20'} text-white mt-[${title ? '75' : '0'}px] md:max-w-[45%] text-start`}>
        <h2 className="text-white text-4xl font-bold mb-3">{title}</h2>
        <p className="text-white/90 mb-6">{text}</p>
        <a
          href={buttonLink}
          target="_blank"
          className=" bg-white text-black px-6 py-2 rounded-md hover:bg-gray-100 transition-colors no-underline font-bold"
        >
          {buttonText}
        </a>
      </div>
      <div className="absolute inset-0 bg-black/50"></div>
    </div>
  )
}

export default ImageAd
