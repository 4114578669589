import React, { useContext, useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Post } from '../api/server';
import GlobalContext from '../contexts/store';

export default function Partnership() {
  const { company } = useContext(GlobalContext)
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    message: ''
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formFields = [
    {
      id: 'name',
      label: 'Your Name',
      type: 'text',
      placeholder: 'Enter your name',
      required: true,
      colSize: 6
    },
    {
      id: 'phone',
      label: 'Phone Number',
      type: 'tel',
      placeholder: 'Enter your phone number',
      required: true,
      colSize: 6
    },
    {
      id: 'email',
      label: 'Email Address',
      type: 'email',
      placeholder: 'Enter your email',
      required: true,
      colSize: 12
    },
    {
      id: 'message',
      label: 'Message',
      type: 'textarea',
      placeholder: 'Tell us about your brand',
      required: true,
      colSize: 12,
      rows: 4
    }
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.name === '' || formData.phone === '' || formData.email === '') {
      toast.error('Please fill out all fields');
      return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      toast.error('Please enter a valid email address');
      return;
    }

    try {
      await Post('/contacts', {
        first_name: formData.name,
        phone: formData.phone,
        email: formData.email,
        subject: 'Partnerships',
        message: formData.message,
        company: company._id
      });

      toast.success('Your message has been sent successfully!');
      setFormData({ name: '', phone: '', email: '', message: '' });
    } catch (error) {
      toast.error('Failed to send message. Please try again.');
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.id]: e.target.value });
  };

  return (
    <>
      <div className="bg-[#101010] min-h-screen py-16 pt-40">
        <Container>
          <Row className="justify-content-center">
            <Col md={10} lg={8}>
              <div className="text-center mb-10">
                <h1 className="text-4xl font-bold text-white mb-4">
                  Partnerships
                </h1>
                <p className="text-gray-300 text-lg mb-8">
                  Welcome to our Partnerships! We proudly collaborate with a diverse range of talented brands,
                  creating synergies for unforgettable events. Dive in to learn more about our partners and
                  the magic we craft together for the ultimate nightlife experience.
                </p>
              </div>

              <div className="bg-white rounded-lg shadow-xl p-8">
                <Form onSubmit={handleSubmit}>
                  <Row>
                    {formFields.map((field) => (
                      <Col md={field.colSize} key={field.id} className="mb-4">
                        <Form.Group>
                          <Form.Label className="font-semibold">
                            {field.label}
                          </Form.Label>
                          <Form.Control
                            id={field.id}
                            type={field.type}
                            as={field.type === 'textarea' ? 'textarea' : 'input'}
                            placeholder={field.placeholder}
                            value={formData[field.id]}
                            onChange={handleChange}
                            required={field.required}
                            rows={field.rows}
                            className="focus:ring-2 focus:ring-blue-500"
                          />
                        </Form.Group>
                      </Col>
                    ))}
                  </Row>

                  <div className="text-right mt-4">
                    <Button
                      onClick={handleSubmit}
                      className="bg-transparent border border-[#101010] border-opacity-100 text-black text-sm font-medium py-2 px-10 rounded-lg cursor-pointer transition-colors duration-300 mx-auto m-1"
                    >
                      Submit Inquiry
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}
