import React, { useContext, useEffect, useState } from 'react'
import LandingHeader from '../components/headers/LandingHeader'
import UpcomingEvents from '../components/sections/UpcomingEvents'
import ImageAd from '../components/ads/ImageAd'
import Faq from '../components/rj-components/Faq'
import headerBg from '../assets/images/headers/4.jpg'
import FixedMenuBar from '../layouts/FixedMenuBar'
import GlobalContext from '../contexts/store'

function Landing() {
  const { events, organizations } = useContext(GlobalContext);
  const [orgEventsMap, setOrgEventsMap] = useState([]);
  const [orgsWithEvents, setOrgsWithEvents] = useState([]);
  

  useEffect(() => {
    // Filter organizations with upcoming events and add their events
    const orgsWithEvents = organizations
      .filter(org => events.some(event => event.organization._id === org._id))
      .map(org => ({
        ...org,
        events: events.filter(event => event.organization._id === org._id)
      }));

    setOrgsWithEvents(orgsWithEvents);
    setOrgEventsMap(orgsWithEvents); // Using the same array for both states
    window.scrollTo(0, 0);
  }, [events, organizations]);

  return (
    <div className='bg-[#101010]'>
      <LandingHeader />
      <div className=' mb-20'>
        <FixedMenuBar orgs={orgEventsMap} />
      </div>

      {orgEventsMap && orgEventsMap.length > 0 && orgEventsMap.map((org, index) => (
      <UpcomingEvents key={index} org={org} title={org.name} id={org.slug} isDark={true} />
      ))}

      <ImageAd
        id="rent-space"
        image={'https://715harrison.s3-us-west-1.amazonaws.com/galleryItems/1714081468494_Stage_view_main_room.jpg'}
        title="Create your own event"
        text="Explore our versatile spaces, state-of-the-art resources, and customizable floor plans designed to bring your next big event to life. Let us help you plan an unforgettable experience tailored to your needs."
        buttonLink="https://715harrison.com"
        buttonText="Rent Space"
      />
      {/* <Partners /> */}
      <Faq id="faq" isDark={true} />
      
    </div>
  )
}

export default Landing